


















































































































































import { defineComponent, computed } from '@vue/composition-api'
import { mapGetters } from '@/store/utils'

export default defineComponent({
  name: 'VArticle',
  components: {},
  setup(props, ctx) {
    const getters = mapGetters(ctx, ['content'])
    const pad = l => l.toString().padStart(2, '0')

    const date = computed(() => {
      if (getters.content.value.articleDate) {
        const d = new Date(getters.content.value.articleDate * 1000)

        return `${pad(d.getDate())}.${pad(d.getMonth() + 1)}.${d.getFullYear()}`
      }

      return ''
    })

    return {
      date,
      ...getters,
    }
  },
})
